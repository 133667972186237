.bible-filter {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    margin-bottom: 5px;
    padding: 5px;

    select {
        cursor: pointer;

        &::after {
            color: #727272;
        }
    }

    input[name=term] {
        &>* {
            color: #727272;
        }
    }

    &>* {
        margin-inline-end: 5px;
        outline: none;
        border: 1px solid #ccc;
        color: #727272;
        padding: 5px;
        border-radius: 5px;
    }
}