* {
    box-sizing: border-box;
}


html {
    direction: rtl;
}

.App {
    min-height: 90vh;
    line-height: 1.8rem;
}

body {
    margin: 0;
    height: 100%;
    font-size: 0.875rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #727272;
}

p{
    margin: 0;
}
main{
    height: 100%;
    width: 100%;
}

h1,h2,h3,h4,h5{
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    background-color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
}

.footer {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0; 
   background-color: #fff;
   text-align: center;
}

.link {
    margin: 0 10px;
}

.main-wrapper {
    padding-bottom: 20px;
}

